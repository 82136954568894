import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyB0ihWBDnan1wHpdKK5PzcQudqnqfBJ1iM",
  authDomain: "pushnotifications-13663.firebaseapp.com",
  projectId: "pushnotifications-13663",
  storageBucket: "pushnotifications-13663.firebasestorage.app",
  messagingSenderId: "501509089091",
  appId: "1:501509089091:web:79ea5ae7337285ca65f1f6",
  measurementId: "G-G3ESCNMWH5",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// Sample notification messages for mock notifications
const notificationMessages = [
  {
    title: "Mock Notification",
    body: "This is a mock notification.",
    url: "http://localhost:3000/",
  },
  {
    title: "Mock Notification 2",
    body: "This is another mock notification.",
    url: "http://localhost:3000/",
  },
  {
    title: "Mock Notification 3",
    body: "This is another mock notification.",
    url: "http://localhost:3000/",
  },
  {
    title: "Mock Notification 4",
    body: "This is another mock notification.",
    url: "http://localhost:3000/",
  }
];

const PushNotifications = () => {
  const [token, setToken] = useState(null);
  const [notifications, setNotifications] = useState(notificationMessages);
  const [hasUserInteracted, setHasUserInteracted] = useState(false); 

  const playNotificationSound = () => {
    if (hasUserInteracted) {
      const audio = new Audio("/mixkit-bell-notification-933.wav");
      audio.play().then(() => {
        console.log("Sound played successfully!");
      }).catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  };

  useEffect(() => {
    const setupNotifications = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        try {
          const currentToken = await getToken(messaging, {
            vapidKey:
              "BJSEbwOAEyUMmNaxv3qRM8ZtintdbSCoHJsSS3iZyb_3CvjRG7pa7Kg2CsAj67AqsOVj7t9tNQqwFJ7vHCL7YUo",
          });
          if (currentToken) {
            console.log("FCM Token:", currentToken);
            setToken(currentToken);
          } else {
            console.log("No FCM token available.");
          }
        } catch (error) {
          console.error("Error retrieving FCM token:", error);
        }
      } else {
        console.log("Notification permission denied.");
      }
    };

    setupNotifications();

    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Message received: ", payload);
      setNotifications((prev) => [
        ...prev,
        {
          title: payload.notification.title,
          body: payload.notification.body,
          url: payload.data?.url || "",
        },
      ]);
      playNotificationSound();
    });

    return () => unsubscribe();
  }, [hasUserInteracted]);

  const handleUserInteraction = () => {
    setHasUserInteracted(true);
    console.log("User interacted with the page.");
  };

  useEffect(() => {
    const sendMockNotification = () => {
        setHasUserInteracted(true);
      if (Notification.permission === "granted") {
        notificationMessages.forEach((notification) => {
          const { title, body, url } = notification;
          const notif = new Notification(title, {
            body: body,
            data: { url: url },
          });
          notif.onclick = (event) => {
            event.preventDefault();
            window.open(notification.url);
          };
          if (hasUserInteracted) {
            playNotificationSound();
          }
        });
      }
    };

    const interval = setInterval(() => {
        sendMockNotification();
        handleUserInteraction();
        console.log("user interacted");
      }, 36000000);
  
      return () => clearInterval(interval);

  }, [hasUserInteracted]);


  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>React Firebase Push Notifications</h1>
      <button
        onClick={handleUserInteraction}
        style={{ padding: "10px 20px", fontSize: "16px", cursor: "pointer" }}
      >
        Click here to allow notification sound
      </button>
    </div>
  );
};

export default PushNotifications;
