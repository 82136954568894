// src/Component/PageNotFound/PageNotFound.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    const navigate = useNavigate(); // React Router hook to navigate programmatically

    const handleGoHome = () => {
        navigate('/'); // Navigate to home page (or a default route)
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            flexDirection="column"
        >
            <Typography variant="h1" component="h2" color="error">
                404
            </Typography>
            <Typography variant="h6" gutterBottom>
                Page Not Found
            </Typography>
            <Button variant="contained" color="primary" onClick={handleGoHome}>
                Go Back to Home
            </Button>
        </Box>
    );
};

export default PageNotFound;
