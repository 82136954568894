import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Avatar,
  CssBaseline,
  Grid,
  Alert,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router";
import { useAuth } from "../../Store/AuthContext";
import { useSignin } from "../../Api/authApi/useSignIn";
import { useVerifyEmail } from "../../Api/authApi/useVerifyEmail";
import CustomModal from "../../Custom/CustomModal";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CustomHeader from "../../Custom/TableHeader/CustomHeader";
import AlertMethod from "../../Custom/Alert/Alert";

const LoginForm = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [userResponse, setUserResponse] = useState({});
  
  const [error, setError] = useState(null);
  const [otpErr, setOtpError] = useState(null);
  
  const { login } = useAuth();
  const navigate = useNavigate();
  
  const [openModel, setOpenModel] = useState(false);
  
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  
  const [verifiedData, setVerifiedData] = useState({
    userId: null,
    code: "",
    type: "email",
  });
  const { verifyOtp, loading: otpLoading, error: otpError } = useVerifyEmail();
  const { signin, loading, error: signinError } = useSignin();

  const focusFirstField = useRef(null);

  useEffect(() => {
    if(focusFirstField.current)
    focusFirstField.current?.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim() && !password.trim()) {
      setError("Email and Password is required.");
      return;
    }
    if (!email.trim()) {
      setError("Email is required.");
      return;
    }

    if (!password.trim()) {
      setError("Password is required.");
      return;
    }

    try {
      const response = await signin({ email, password });
      setUserResponse(response);
      if (response?.Token) {
        const isAdmin = response?.Roles?.some(role => role === "admin");
        const now = new Date().getTime();
        localStorage.setItem("userTimeExpiration", now);
        login(response.Token, isAdmin);
        navigate("/");
        setError(null);
      }
    } catch (err) {
      console.log(err, "this err only")
      console.log(err.message, err.status)
      // if(err.status === 404){
      //   setError(err.message);
      // }
      // if(err.status === 401){
      //   setError(err.message);
      // }
      // if(err.status === 400){
      //   setError(err.message);
      // }
      // if(err.status === 500){
      //   setError(err.message);
      // }
      if (err.status === 403) {
        setError(err.message.message);
        setOpenModel(true);
        setVerifiedData({
          userId: err.message.userId,
          code: "",
          type: "email",
        })
      } else {
        if (err.status === 401) {
          setError("Invalid email or password.");
        }
        if (err.status === 404) {
          setError("Page not found.");
        }
      }
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const verifiedResponse = await verifyOtp(verifiedData);
      console.log(verifiedResponse)
      setAlertMessage("Email verified successfully!");
      setAlertSeverity("success");
      setAlertOpen(true);
      setOpenModel(false);
      if (verifiedResponse?.Token) {
        const isAdmin = verifiedResponse?.Roles?.some(role => role === "admin");
        const now = new Date().getTime();
        localStorage.setItem("userTimeExpiration", now);
        login(verifiedResponse.Token, isAdmin);
        navigate("/");
      }
    } catch (err) {
      const waitForOtpError = async () => {
        const timeout = new Promise((resolve) => setTimeout(resolve, 500));
        await timeout;

        const finalError = otpError || err.response?.data || "An unexpected error occurred.";
      setOtpError(finalError);
        setAlertMessage("Error verifying OTP: " + finalError);
        setAlertSeverity("error");
        setAlertOpen(true);
      };

      waitForOtpError();
    }
  };

  const icons = [
    {
      tooltip: 'Cancel',
      icon: <CancelIcon />,
      color: 'error',
      onClick: () => {
        setOpenModel(false);
        setOtpError(null);
        setError(null);
      },
    },
    {
      tooltip: otpLoading ? 'Loading...' : 'Verify OTP',
      icon: <CheckCircleIcon />,
      color: 'success',
      type: 'submit',
    },
  ];


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AlertMethod
        open={alertOpen}
        alertMessage={alertMessage}
        alertSeverity={alertSeverity}
        handleAlertClose={() => setAlertOpen(false)}
      />
      <CustomModal open={openModel} onClose={() => setOpenModel(false)}>
        <Box component="form" onSubmit={handleOtpVerification}>
          <CustomHeader title="Verify Email" icons={icons} />
          {(otpErr) ? (
            <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
              {otpErr}
            </Alert>
          )
            : (
              <Alert severity="success" sx={{ mt: 2, width: "100%" }}>
                Otp sent to your email
              </Alert>
            )
          }
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Enter Email OTP"
              name="code"
              type="text"
              inputRef={focusFirstField}
              value={verifiedData.code || ''}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^\d{0,10}$/.test(inputValue)) {
                  setVerifiedData((prev) => ({
                    ...prev,
                    [e.target.name]: inputValue,
                  }));
                }
              }}
              error={!!verifiedData.code && verifiedData.code.length !== 4}
              helperText={
                !!verifiedData.code && verifiedData.code.length !== 4
                  ? 'OTP must be 4 digits only'
                  : ''
              }
              margin="normal"
            />
          </Grid>
          {/* <Button
            type="submit"
            fullWidth
            variant="outlined"

            sx={{ mt: 3, mb: 2 }}
          >
            {otpLoading ? 'Loading...' : 'Verify OTP'}
          </Button> */}
        </Box>
      </CustomModal>


      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        {(error) && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? "loading..." : "Sign In"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                "Don't have an account? Sign Up"
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
