import React from "react";
import PushNotifications from "../../PushNotification";


const Dashboard = () => {
    return (
        <div>
            <h2>Welcome to the site!</h2>
            <p>This is a responsive layout with a header, sidebar, and footer.</p>
            <PushNotifications />
        </div>
    )
}

export default Dashboard;