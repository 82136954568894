import { useState } from "react";
import apiService from "../axiosIntance";

export const useSignin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const signin = async (credentials) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.post("/user/signin", credentials);
      return response.data;
    } catch (err) {
      const status = err.response?.status;
      const message = err.response?.data || err.message;
      const errorMessage = { message, status };

      if ([400, 401, 403, 404, 500].includes(status)) {
        setError(errorMessage);
        throw errorMessage;
      }

      setError({ message: "Failed to sign in.", status: "unknown" });
      throw { message: "Failed to sign in.", status: "unknown" };
    } finally {
      setLoading(false);
    }
  };

  return { signin, loading, error };
};
