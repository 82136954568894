import React, { useState } from 'react';
import { List, ListItem, ListItemText, Collapse, Box, Divider } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const NavList = ({ items, toggleDrawer }) => {
  const [openSubMenus, setOpenSubMenus] = useState({});
  const navigate = useNavigate();

  const handleSubMenuToggle = (name) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const renderNavItems = (items) =>
    items.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem
          button
          onClick={
            item.to
              ? () => {
                  navigate(item.to);
                  if (toggleDrawer) toggleDrawer();
                }
              : () => handleSubMenuToggle(item.name)
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {item.icon}
            <ListItemText primary={item.name} />
          </Box>
          {item.items ? (
            openSubMenus[item.name] ? <ExpandLess /> : <ExpandMore />
          ) : null}
        </ListItem>
        {item.items && (
          <Collapse in={openSubMenus[item.name]} timeout="auto" unmountOnExit>
            <List component="div" style={{ paddingLeft: '2rem' }}>
              {renderNavItems(item.items)}
            </List>
          </Collapse>
        )}
        {index < items.length - 1 && <Divider />}
      </React.Fragment>
    ));

  return <List>{renderNavItems(items)}</List>;
};

export default NavList;
