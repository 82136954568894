import React, { Suspense, useState } from 'react';
import {
    CssBaseline,
    Box,
    Drawer,
    Divider,
    Toolbar,
    Container,
    CircularProgress,
} from '@mui/material';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import Header from '../Header/Header';
import NavItems from '../../NavItems';
import BreadcrumbsComponent from '../BreadCrumb/BreadcrumbsComponent';


const DashboardLayout = () => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const isAdmin = localStorage.getItem("admin");

    const isSmallScreen = useMediaQuery('(max-width:800px)');

    const toggleDrawer = () => setDrawerOpen(!drawerOpen);
    const toggleSidebar = () => setDrawerOpen(!drawerOpen); 
    const toggleAuth = () => {
        if (token) {
            localStorage.clear();
            navigate("/login");
            setIsAuthenticated(false);
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header
                toggleSidebar={toggleSidebar}
                toggleAuth={toggleAuth}
                isAuthenticated={isAuthenticated}
                token={token}
            />
            <Drawer
                variant={isSmallScreen ? 'temporary' : 'persistent'}
                open={drawerOpen}
                onClose={isSmallScreen ? toggleDrawer : undefined}
                ModalProps={{ keepMounted: true }}
                sx={{
                    '& .MuiDrawer-paper': {
                        pt: 3.1,
                        width: drawerOpen ? 240 : 0,
                        boxSizing: 'border-box',
                        bgcolor: '#2e70cd',
                        color: '#fff',
                        transition: 'width 0.4s ease', 
                        overflowX: 'hidden', 
                    },
                }}
            >
                <Toolbar />
                <Divider />
                <NavItems toggleDrawer={isSmallScreen ? toggleDrawer : null} />
            </Drawer>

            {/* Main Content */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    pt: 4,
                    bgcolor: '#f4f4f4',
                    minHeight: '100vh',
                    marginLeft: isSmallScreen ? 0 : `${drawerOpen ? 240 : 0}px`,
                    transition: 'margin-left 0.4s ease',
                }}
            >
                <Toolbar />
                <Container sx={{ mt: 1 }}>
                    {/* Breadcrumbs Section */}
                    <Box
                        sx={{
                            p: 1,
                            bgcolor: 'white',
                            borderRadius: 1,
                            boxShadow: 1,
                            textAlign: 'center',
                            mb: 1,
                        }}
                    >
                        <BreadcrumbsComponent />
                    </Box>

                    {/* Content Section */}
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: 'white',
                            borderRadius: 1,
                            boxShadow: 1,
                        }}
                    >
                        {token && isAdmin ? (
                            <Suspense fallback={<CircularProgress />}>
                                <Outlet />
                            </Suspense>
                        ) : (
                            <Navigate to="/login" />
                        )}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default DashboardLayout;
