import { useState } from "react";
import apiService from "../axiosIntance";

export const useVerifyEmail = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const verifyOtp = async (data) => {
    setError(null);
    setLoading(true);
    try {
      const response = await apiService.post("/verify-code", data);
      return response.data;
    } catch (err) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const errorMessage = err.response?.data || "OTP IS INCORRECT.";
      setError(errorMessage);
      throw errorMessage;
    } finally {
      setLoading(false);
    }
  };

  return { verifyOtp, error, loading };
};
