import React, { lazy } from 'react';
import { useAuth } from './Store/AuthContext';
import DashboardLayout from './Component/Layout/DefaultLayout';

const Dashboard = lazy(() => import('./View/Dashboard/Dashboard'));
const UsersList = lazy(() => import('./View/User/UsersList'));
const RolesList = lazy(() => import('./View/Role/RolesList'));
const GetPermissionRoles = lazy(() => import('./View/Permission/GetRolePermission'));
const ShiftList = lazy(() => import('./View/Shift/ShiftList'));
const FrequencyList = lazy(() => import('./View/RemainderFrequency/FrequencyList'));
const DesignationList = lazy(() => import('./View/Designation/DesignationList'));
const UserProfile = lazy(() => import('./View/User/UserProfile'));
const ReminderList = lazy(() => import('./View/Reminder/ReminderList'));
const RoleDetails = lazy(() => import('./View/Role/RoleDetails'));

const RoutesURL = () => {
    const { token, isAdmin } = useAuth(); 

    const baseRoutes = [
        {
            name: "Home",
            path: "/",
            element: <DashboardLayout />,
        },
        {
            name: "Dashboard",
            path: "/dashboard", 
            element: <Dashboard />,
        },
        {
            name: "Reminder",
            path: "/reminder",
            element: <ReminderList />,
        },
    ];

    const adminRoutes = [
        {
            name: "Role",
            path: "/role",
            element: <RolesList />,
        },
        {
            name: "Role Details",
            path: "/role/:roleId/details",
            element: <RoleDetails />,
        },
        {
            name: "User",
            path: "/user",
            element: <UsersList />,
        },
        {
            name: "User Details",
            path: "/user/:userId/details",
            element: <UserProfile />,
        },
        {
            name: "Permission",
            path: "/permission",
            element: <GetPermissionRoles />,
        },
        {
            name: "Shifts",
            path: "/shifts",
            element: <ShiftList />,
        },
        {
            name: "Frequency",
            path: "/frequency",
            element: <FrequencyList />,
        },
        {
            name: "Designation",
            path: "/designation",
            element: <DesignationList />,
        },
    ];
    return token && isAdmin !== "false" ? [...baseRoutes, ...adminRoutes] : baseRoutes;
};

export default RoutesURL;
