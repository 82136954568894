import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Container, Alert, Grid, Link, Box, Avatar, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../../Api/authApi/useSignUp";
import AlertMethod from "../../Custom/Alert/Alert";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { countries } from "../../hooks/CountryCode/useCountryCodeApi";


const SignUp = () => {
  const [formData, setFormData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    PhoneNumber: "",
    countryCode: "+91",
    location: "",
    password: "",
  });

  const [step, setStep] = useState(1);

  const [selectedOptions, setSelectedOptions] = useState(countries);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const [errors, setErrors] = useState({});

  const [isFormValid, setIsFormValid] = useState(false);

  const navigate = useNavigate();

  const { signup, loading, error } = useSignup();

  const validationRules = {
    username: (value) => (value.trim() ? "" : "Username is required"),
    firstName: (value) => (value.trim() ? "" : "First Name is required"),
    lastName: (value) => (value.trim() ? "" : "Last Name is required"),
    location: (value) => (value.trim() ? "" : "Location is required"),
    PhoneNumber: (value) =>
      !value
        ? "Phone number is required"
        : /^\+?[1-9]\d{1,14}$/.test(value)
          ? ""  // E.164 format check: optional '+' followed by digits (1 to 15 digits)
          : "Phone number must be in E.164 format (e.g., +1234567890)",
    email: (value) =>
      !value
        ? "Email is required"
        : /\S+@\S+\.\S+/.test(value)
          ? ""
          : "Email is invalid",
    password: (value) => (value.trim() ? "" : "Password is required"),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validationRules[name](value),
    }));

    if (name === "email") step >= 2 || setStep(2);
    if (name === "firstName") step >= 3 || setStep(3);
    if (name === "lastName") step >= 4 || setStep(4);
    if (name === "username") step >= 5 || setStep(5);
    if (name === "location") step >= 7 || setStep(7);
    if (name === "password") step >= 8 || setStep(8);

  };


  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  useEffect(() => {
    const isValid =
      Object.values(formData).every((value) => value.trim()) &&
      Object.values(errors).every((error) => !error);
    setIsFormValid(isValid);
  }, [formData, errors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await signup(formData);
      if (response) {
        navigate("/login");
      }
    } catch (err) {
      setAlertMessage(error || err.response?.data || 'Error creating user.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setFormData((prev) => ({
        ...prev,
        countryCode: newValue.phone,
      }));
    }
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ backgroundColor: "white", mt: "50px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Sign Up
        </Typography>

        <AlertMethod
          alertMessage={alertMessage}
          alertOpen={alertOpen}
          alertSeverity={alertSeverity}
          handleAlertClose={handleAlertClose}
        />

        <Box component="form" onSubmit={handleSubmit} noValidate >
          <Grid container columnSpacing={2} rowSpacing="1px" width={500}>

            {step >= 1 && (
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  margin="normal"
                />
              </Grid>
            )}

            {step >= 2 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  margin="normal"
                />
              </Grid>

            )}
            {step >= 3 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  error={!!errors.LastName}
                  helperText={errors.LastName}
                  margin="normal"
                />
              </Grid>
            )}

            {step >= 4 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  error={!!errors.username}
                  helperText={errors.username}
                  margin="normal"
                />
              </Grid>
            )}

            {step >= 5 && (
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={selectedOptions}
                  name="countryCode"
                  getOptionLabel={(option) => `${option.label} (${option.phone})`}
                  value={countries.find((country) => country.phone === formData.countryCode) ||
                    countries.find((country) => country.phone === "91")}
                  onChange={handleCountryChange}
                  renderInput={(params) => <TextField {...params} label="Country" />}
                  fullWidth
                />
              </Grid>
            )}

            {/* {step >= 5 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="PhoneNumber"

                  value={formData.PhoneNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (step <= 6) setStep(6);
                    // Allow the phone number to include optional "+" and up to 15 digits
                    if (/^(\+?\d{0,10})$/.test(inputValue)) {
                      setFormData((prev) => ({
                        ...prev,
                        [e.target.name]: inputValue,
                      }));
                    }
                  }}
                  error={!!errors.PhoneNumber}
                  helperText={errors.PhoneNumber}
                  margin="normal"
                />
              </Grid>
            )} */}
            {step >= 5 && (

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  name="PhoneNumber"
                  value={`${formData.countryCode} ${formData.PhoneNumber}`}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(formData.countryCode, '').trim();
                    if (/^\d{0,10}$/.test(inputValue)) {
                      if (step <= 6) setStep(6);
                      setFormData((prev) => ({
                        ...prev,
                        PhoneNumber: inputValue,
                      }));

                    }
                  }}
                  fullWidth
                  required
                  error={!!errors.PhoneNumber}
                  helperText={errors.PhoneNumber}
                  margin="normal" />
              </Grid>
            )}

            {step >= 6 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  margin="normal"
                />
              </Grid>
            )}

            {step >= 7 && (
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Location"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  error={!!errors.location}
                  helperText={errors.location}
                  margin="normal"
                />
              </Grid>
            )}

          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            // disabled={!isFormValid || loading}
            sx={{ mt: 3, py: 1 }}
          >
            {loading ? "Submitting..." : "Sign Up"}
          </Button>


        </Box>
        <Typography align="center" sx={{ mt: 2 }}>
          Already have an account?{" "}
          <Link href="/login" underline="hover">
            Login here
          </Link>
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUp;
