export const checkAndRemoveExpiredToken = () => {
    const loginTimestamp = localStorage.getItem('userTimeExpiration');

    if (loginTimestamp) {
      const now = new Date().getTime();
      const expiryTime = 60 * 60 * 1000;
      if (now - loginTimestamp > expiryTime) {
        localStorage.clear(); 
        console.log('Session expired. Local storage cleared.');
      }
    }
};