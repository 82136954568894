import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('admin'));

  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    const savedIsAdmin = localStorage.getItem("admin");
    if (savedToken || savedIsAdmin) {
      setToken(savedToken);
      setIsAdmin(savedIsAdmin);
    }
  }, []);

  const login = async (token,role) => {
    setToken(token);
    setIsAdmin(role);
    localStorage.setItem('token', token);
    localStorage.setItem('admin', role);
  };

  const logout = () => {
    setToken(null);
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ token,isAdmin, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
