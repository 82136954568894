import HomeIcon from '@mui/icons-material/Home';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAuth } from "./Store/AuthContext";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { ImageOutlined } from '@mui/icons-material';
import NavList from './Component/SideBar/renderNavItems';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ScheduleIcon from '@mui/icons-material/Schedule';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PersonIcon from '@mui/icons-material/Person';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


// import PersonIcon from '@mui/icons-material/Person';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import LockOpenIcon from '@mui/icons-material/LockOpen';




const NavItems = ({toggleDrawer}) => {
    const { token } = useAuth();
    const {isAdmin} = useAuth();
    const items = [
        {
            name: "Home",
            to: "/",
            icon: <HomeIcon />,
        },
        {
            name: "Reminders",
            to: "/reminder",
            icon: <AccessAlarmIcon />,
        }
    ];

    if (isAdmin === "true" || isAdmin === true) {
        items.push({
            name: "Admin",
            icon: <AdminPanelSettingsIcon />,
            items: [
                {
                    name: "Users",
                    to: "/user",
                    icon: <PersonOutlineIcon />,
                },
                {
                    name: "Roles",
                    to: "/role",
                    icon: <SupervisorAccountOutlinedIcon />,
                },
                {
                    name: "Permissions",
                    to: "/permission",
                    icon: <LockOpenIcon />,
                },
                {
                    name: "Shift",
                    to: "/shifts",
                    icon: <ScheduleIcon />,
                },
                {
                    name: "Remainder Frequency",
                    to: "/frequency",
                    icon: <EventRepeatIcon />,
                },
                {
                    name: "Designation",
                    to: "/designation",
                    icon: <BadgeOutlinedIcon />,
                },
            ],
        });
    }

    return <NavList items={items} toggleDrawer={toggleDrawer} />;;
};


export default NavItems;