import React from "react";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

const CustomHeader = ({ title, icons }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top={0}
      zIndex={1}
    >
      {/* Title */}
      <Typography variant="h6" sx={{ color: "#1976d2", fontWeight: "800" }} gutterBottom>
        {title}
      </Typography>

      {/* Icons */}
      <Stack direction="row" spacing={2}>
        {icons &&
          icons.map((icon, index) => (
            <Tooltip key={index} title={icon.tooltip}>
              <IconButton
                type={icon.type || "button"} 
                color={icon.color || "primary"}
                onClick={icon.onClick}
                disabled={icon.disabled}
              >
                {icon.icon} 
              </IconButton>
            </Tooltip>
          ))}
      </Stack>
    </Box>
  );
};

export default CustomHeader;
