import { useState } from "react";
import apiService from "../axiosIntance";

export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const signup = async (data) => {
    setLoading(true);
    setError(null);
    try {
      const response = await apiService.post("/user/signup", data);
      return response.data;
    } catch (err) {
      const errorDetails = err.response?.data?.details;
      if (errorDetails) {
        if (errorDetails.PhoneNumber) {
          setError(errorDetails.PhoneNumber);
        } else {
          setError(err.response?.data?.error || "Failed to sign up.");
        }
      } else {
        setError(err?.message || "Failed to sign up.");
      }
      throw new Error(error || "Unknown error");
    } finally {
      setLoading(false);
    }
  };

  return { signup, loading, error };
};
