import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './Store/AuthContext';
import LoginForm from './Pages/Auth/SignIn';
import SignUp from './Pages/Auth/SignUp';
import Dashboard from './View/Dashboard/Dashboard';
// import { addNotification, generateToken, getNotifications, messaging } from './Config/PushNotification/firebaseConfig';
// import { onMessage } from 'firebase/messaging';
import RoutesURL from './route';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import DashboardLayout from './Component/Layout/DefaultLayout';
import { checkAndRemoveExpiredToken } from './utils/checkAndRemoveExpiredToken';

const App = () => {
  const routes = RoutesURL();
  const { token } = useAuth();

  useEffect(() => {
    checkAndRemoveExpiredToken();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<SignUp />} />

        {token ? (
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            {routes?.map(({ name, path, element }) => (
              <Route key={name} path={path} element={element} />
            ))}
            <Route path="*" element={<PageNotFound />} />
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}

      </Routes>
    </Router>
  );
};

export default App;
