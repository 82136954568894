import { Box, Snackbar } from "@mui/material";
import Alert from '@mui/material/Alert';


const AlertMethod = ({alertMessage,alertOpen,alertSeverity,handleAlertClose}) => {

    return(
             <Snackbar
                open={alertOpen}
                autoHideDuration={1000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
    )
}

export default AlertMethod;