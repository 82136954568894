import React from "react";
import { Dialog, DialogContent } from "@mui/material";

const CustomModal = ({ open, onClose, children }) => {

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return; 
    }
    onClose(event); 
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
