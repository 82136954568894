import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Button, Box, Typography, Tooltip, Avatar, Menu, MenuItem, Divider } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LogoIcon from '../../Asserts/Images/Logoicon.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Header = ({ toggleSidebar, toggleAuth, isAuthenticated, token }) => {


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Box
      component="header"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        backgroundColor: "white",
        color: "black",
        position: "fixed",
        width: "100%",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <IconButton
          onClick={toggleSidebar}
          color="inherit"
          sx={{ fontSize: "30px", fontWeight: 900 }}
        >
          <MenuOpenIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "800",
              fontSize: "1.8rem",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              fontStyle: "italic",
            }}
          >
            <img src={LogoIcon} alt="Logo" style={{ width: "2rem", height: "2rem", marginRight: "0.5rem" }} />
            {/* <AccessAlarmIcon sx={{ fontSize: "1.8rem",mr:1 }} /> */}
            Wishowish
          </Link>
        </Typography>
      </Box>
      {/* <Tooltip title="Profile">
        <IconButton
          color="primary"
          sx={{ fontSize: "30px", fontWeight: 900 }}
        >
          <AccountCircleIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
      </Tooltip> */}
      <div>
        <IconButton onClick={handleClick} color="primary">
          {/* <Avatar alt="User Name" src="/path-to-avatar.jpg" /> */}
          <AccountCircleIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ mt: 2,mr:4,width: 250 }}
          MenuProps={{
            PaperProps: {
              sx: {
                width: 250, 
              },
            },
          }}
        >
          <MenuItem onClick={handleClose}>
            <Typography>Profile</Typography>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Typography>Settings</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <Link
              to="/login"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                display: 'block',
              }}
            >
              <Button
                color="success"
                onClick={toggleAuth}
                sx={{
                  textTransform: 'none',
                }}
              >
                Sign Out
              </Button>
            </Link>
          </MenuItem>
        </Menu>
      </div>
      {/* <Button
        variant="outlined"
        color="success"
        onClick={toggleAuth}
        sx={{
          "& a": { textDecoration: "none", color: "black", },
        }}
      >
        <Link to={isAuthenticated ? "/" : "/login"}>
          {token ? "Sign Out" : "Sign In / Sign Up"}
        </Link>
      </Button> */}
    </Box>
  );
};

export default Header;
